import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageConstants } from "../../constants/Imagconstants";
import { workSlider } from "../../constants/swiperConstants";

const TeamSlider = () => {
  return (
    <StaticQuery
      query={graphql`
        query Team {
          allStrapiTeam {
            nodes {
              Name
              designation
              image {
                url
              }
            }
          }
          allStrapiTrustUs {
            nodes {
              images {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const slides = data?.allStrapiTeam?.nodes;
        console.log("saar", data)
        return (
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="sub-width-60">
                    <h2 className="commantitel">
                      Meet our <br /> leadership team
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <Swiper className="workslider" {...workSlider}>
              <div className="swiper-wrapper">
                {slides?.map((s, id) => {
                  return (
                    <SwiperSlide>
                      <div className="leader-box left-top-corner-radius">
                        <figure>
                          <img
                            loading="lazy"
                            src={s?.image?.url}
                            alt="anshul"
                          />
                        </figure>
                        <h5>{s?.name}</h5>
                        <p>{s?.designation}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
              <div className="swiper-button-next">
                <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
              </div>
              <div className="swiper-button-prev">
                <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
              </div>
            </Swiper>
          </section>
        );
      }}
    />
  );
};

export default TeamSlider;
